import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from '../components/Container'
import Button from '../components/Button'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container size="small" className="text-center space-y-10">
    <h1 className="text-7xl">Page Not Found</h1>
    <p>It looks like we can't find the page you're looking for.</p>
    <Button to={`/`} variation="secondary">Home</Button>
    </Container>

  </Layout>
)

export default NotFoundPage
