import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'gatsby'
// import { OutboundLink } from "gatsby-plugin-google-analytics"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { css } from '@emotion/react'

const Btn = props => {

	const { as: Component, variation = "primary", invert, size = "medium", type, children, ...rest} = props;

	// const getColor = [
	// 	(variation === "secondary") ? (
	// 		'var(--color-secondary)'
	// 	) : (variation === "outline") ? (
	// 		'var(--color-black)'
	// 	) : (variation === "whiteline") ? (
	// 		'var(--color-white)'
	// 	) : (
	// 		'var(--color-primary)'
	// 	)
	// ]

	let outlineColor = 'var(--color-black)';
	let outlineText = '#ffffff';

	if (invert === true) {
		outlineColor = '#ffffff';
		outlineText = 'var(--color-black)';
	}

	const generalStyle = [
		css`
		outline: none;
		border: none;
		display: inline-block;
		box-sizing: border-box;
		padding: 0.65rem 1.1rem;
		font-size: 0.94rem;
		font-weight: 600;
		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 4px inset;
		text-align: center;
		color: var(--color-black);
		transition: all 200ms ease-in-out;
		border: 2px solid var(--color-black);
		text-transform: lowercase;
		letter-spacing: 0.5px;
		text-indent: 0px;
		font-family: var(--font-secondary);
		box-sizing: border-box;

		${

		variation === "primary" &&
		`
		background-color: var(--color-primary);
		border-color:  var(--color-primary);
		color: white;

			&:hover {
				background-color: var(--color-primary-lighter);
				border-color:  var(--color-primary-lighter);
				color: white;
			}
		`

		}

		${

		variation === "secondary" &&
		`
		background-color: var(--color-secondary);
		border-color:  var(--color-secondary);
		color: rgba(0,0,0,0.33);

			&:hover {
				background-color: var(--color-secondary-darker);
				border-color:  var(--color-secondary-darker);
				color: var(--color-black);
			}
		`

		}

		${

		variation === "outline" &&
		`
		background-color: transparent;
		border:  1px solid ${outlineColor};
		color: ${outlineColor};
		padding: 0.7125rem 1.1625rem;
		z-index: 35;
		position: relative;
		border-radius: 0;

			&:after {
				content: "";
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				display: block;
				border: 1px solid ${outlineColor};
				position: absolute;
				width: 100%;
				height: 100%;
			}

			&:before {
				content: "";
				transition: all 200ms ease-in-out;
				bottom: 0;
				left: 0;
				right: 0;
				top: auto;
				display: block;
				border: 1px solid ${outlineColor};
				position: absolute;
				width: 100%;
				height: 100%;
				transform: scaleY(0.0) translateZ(0.0);
				transform-origin: bottom;
				background-color: ${outlineColor};
				z-index: -1;
			}

			&:hover {
				background-color: transparent;
				border-color: ${outlineColor};
				color: ${outlineText};
			}

			&:hover:before {
				transform: scaleY(1.0) translateZ(0.0)
			}
		`

		}

		${size === "large" &&
			`
			padding: 1.15rem 1.35rem 1.28rem 1.35rem;
			font-size: 1.35rem;
			`
		}
		`,

	]

	let target = null;
	let rel = null;

	if (type === "external") {
		target = `_blank`;
		rel = `noopener noreferrer`;
	}

	return (
		<Component
		css={[
			generalStyle,
		]}
		target={target}
		rel={rel}
		{...rest}
		>
			{children}
		</Component>
	)
}

Btn.defaultProps = {
	as: Link,
	type: `internal`,
	invert: false,
	size: `medium`,
}


export class Button extends Component {
	render() {
		const {type, variation, children, invert, size, ...rest} = this.props;

		let getAs = Link;

		if (type === "external") {
			getAs = `a`;
		}

		if (type === "button") {
			getAs = `button`;
		}

		if (type === "anchor") {
			getAs = AnchorLink;
		}

		if (type === "a") {
			getAs = `a`;
		}

		return (
			<Btn variation={variation} type={type} as={getAs} invert={invert} size={size} {...rest}>
				{children}
			</Btn>
		)
	}
}

Button.defaultProps = {
	invert: false,
	size: null,
}

export default Button
